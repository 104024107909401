import React, { Component } from 'react'
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import slide1 from '../Img/slide_1-medeco-eos-model.jpg';
import slide2 from '../Img/slide_2-medeco-eos-module.jpg';
import slide3 from '../Img/slide_3-medeco-eos-module.jpg';
import slide4 from '../Img/slide_4-medeco-eos-module.JPG';

var left = "<";
var right = ">";

class Carousel extends Component{
  state = {
    currentIndex: 0,
    responsive: { 0: { items: 1 }}
  }
  onSlideChanged = (e) => this.setState({ currentIndex: e.item });

  slideNext = () => this.setState({ currentIndex: this.state.currentIndex + 1 });
  slidePrev = () => this.setState({ currentIndex: this.state.currentIndex - 1 });
  render() {

    const {currentIndex} = this.state
    const handleOnDragStart = e => e.preventDefault()

    return(
      <div className="carousel">
      <div className="carButtons">
        <div className="carbut fasx fa-angle-rightx flipx}" onClick={() => this.slidePrev()}>{left}</div>
        <div className="carbut fasx fa-angle-rightx" onClick={() => this.slideNext()}>{right}</div>
      </div>
        <AliceCarousel
          mouseDragEnabled
          slideToIndex={currentIndex}
          onSlideChanged={this.onSlideChanged}
          autoPlayInterval={6000}
          autoPlay={true}
          dotsDisabled={true}
          stopAutoPlayOnHover={false}
          disableAutoPlayOnAction={true}
          buttonsDisabled={true}>
          <img src={slide1} ref="alicesize" alt="slide1" onDragStart={handleOnDragStart} className="slide" />
          <img src={slide2} alt="slide2" onDragStart={handleOnDragStart} className="slide" />
          <img src={slide3} alt="slide3" onDragStart={handleOnDragStart} className="slide" />
          <img src={slide4} alt="slide4" onDragStart={handleOnDragStart} className="slide" />
        </AliceCarousel>

      </div>
    )
  }
}

export default Carousel;
