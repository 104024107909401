import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

class NiceButton extends Component {

  render() {
    return (
      <AnchorLink href= {this.props.href}>
      <div className="nice-button-wrapper">
          <div className="nice-button">
            <div className="nice-button-overlay">{this.props.text}</div>
            <div className="nice-button-text">{this.props.text}</div>
          </div>
      </div>
      </AnchorLink>
    );
  }
}

export default NiceButton;
