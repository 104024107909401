import React, { Component } from 'react';

import './App.css';
import './fa/css/all.css'

import Carousel from './Components/Carousel.js'
import TopBar from './Components/TopBar.js';
import Plane from './Components/Plane.js';

import trlbzrIMG from './Img/300.PNG';
import trlbzrtxt from './Texts/trlbzr.js';

import bioportIMG from './Img/301.PNG';
import bioporttxt from './Texts/bioport.js';
import bioportmoretxt from './Texts/bioport-more.js';

import d45brdIMG from './Img/302.PNG';
import d45brdtxt from './Texts/d45hbrd.js';

var handleScroll = function(prop){
  if(window.pageYOffset<prop.refs.carousel.refs.alicesize.offsetHeight-100){
    prop.setState({topstate: "hidebar"})
  }else{
    prop.setState({topstate: ""})
  }

}

class App extends Component {

  constructor(props) {
    super(props);
    this.state ={topstate: "hidebar"}
  }

  componentDidMount() {
      window.addEventListener('scroll', () => {handleScroll(this)});
  }

  componentWillUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
  }

  render() {

    return (
      <div className="App">
          <TopBar parent={this.state} ref="topbar"/>
          <Carousel ref="carousel"/>
          <Plane title="TRLBZR" sub="[ˈtreɪlˌbleɪ zər]" img={trlbzrIMG} dir={true} text={trlbzrtxt} anchorid="trlbzr" repair={true}/>
          <Plane title="D45HBRD" sub="[ˈdæʃˌbɔrd]" img={d45brdIMG} dir={false} text={d45brdtxt} anchorid="d45hbrd" repair={true}/>
          <Plane title="BIOPORT" sub="[ˌbaɪ oʊˈpɔrt]" img={bioportIMG} dir={true} text={bioporttxt} anchorid="bioport" repair={true} more={bioportmoretxt}/>
          <footer>
            <a href="mailto:trailblazer@trlbzr.com"><div class="far fa-envelope fa"/>trailblazer@trlbzr.com</a><br/>
            <div>Ⓒ 2018-2019
            TRLBZR
            </div><br/>
            <div className="footer-minor">by Jerzy Goebel</div>
            <div className="footer-minor">design Justyna Czekała</div>
          </footer>
      </div>
    );
  }
}

export default App;
