import React, { Component } from 'react';

class Plane extends Component {

  constructor(props){
    super(props)
    this.state = {moreToggled: false,
                  moreClass: "more-closed",
                  offset: {marginBottom : ""},
                  moreWrapper: {height: 0}}

    this.toggleMore = this.toggleMore.bind(this)
  }

  componentDidMount(){

  }

  toggleMore(){
    console.log(this.refs.moreText.offsetHeight);
    this.setState(state => ({
      moreToggled: !state.moreToggled
    }));
    if(this.state.moreToggled){
      this.setState(state => ({
        moreClass: "more-closed",
        offset: {marginBottom : ""},
        moreWrapper: {}
      }));
    }else{
      this.setState(state => ({
        moreClass: "more-opened",
        offset: {marginBottom : this.refs.moreText.offsetHeight},
        moreWrapper: {height : this.refs.moreText.offsetHeight}
      }));
    }
  }

  renderPic(props){
    return(
      <div className="pic">
      <img className="inspic" alt="plane-img" src={this.props.img}/>
      </div>
    )
  }

  renderText(props){
    return(<div className="plane-text">
      <div className="title">{props.title}</div>
      <div className="subtitle">{props.sub}</div>
      <div ref="rawText">
      {props.text.split('\n').map((item, key) => {return <span key={key}>{item}<br/></span>})}
      </div>
      {props.more !== undefined &&
        <div className="more-wrapper">
          <p/>
          <div className="more-button-wrapper">
            <div className={"more-button " + this.state.moreClass} onClick= {this.toggleMore}>
              >
            </div>
          </div>
          <div className="more-text-wrapper" style={this.state.moreWrapper}>
            <div className={"more-text " + this.state.moreClass} ref="moreText">
              {props.more.split('\n').map((item, key) => {return <span key={key}>{item}<br/></span>})}
            </div>
          </div>
        </div>
        }
      </div>
    )
  }

  render(){

    return(
      <div className="maxiplane"  style={this.state.offset}>
      {this.props.repair === true && <section id={this.props.anchorid} className="repairSection"/>}
      {this.props.repair === false && <section id={this.props.anchorid} />}
        <div className="plane">
            {this.props.dir === true &&
              <div className="container">
                {this.renderPic(this.props)}
                {this.renderText(this.props)}
              </div>
            }
            {this.props.dir === false &&
              <div className="container">
                {this.renderText(this.props)}
                {this.renderPic(this.props)}
              </div>
            }
        </div>
      </div>
      )
  }
}


export default Plane;
