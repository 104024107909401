const d45brdtxt = `
D45HBRD to personalizowany system inteligentnego budynku opracowany z myślą o wygodnym i przyjemnym zarządzaniu domem.

    - elementy wykonywane na zamówienie
    - panele dotykowe w 16 milionach kolorów
    - 100% personalizowany interfejs
    - dostęp z każdego urządzenia
    - połączenie z dowolnym systemem monitoringu i alarmu
`
export default d45brdtxt;
