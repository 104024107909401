import React, { Component } from 'react';
import NiceButton from './NiceButton.js';
import AnchorLink from 'react-anchor-link-smooth-scroll';

class TopBar extends Component {

  render() {
    return (
        <div className={"topBar " + this.props.parent.topstate} ref="topbar">
          <AnchorLink href="#trlbzr"><div className="logo">TRLBZR</div></AnchorLink>
          <div className="buttonsContainer">
            <NiceButton text="TRLBZR" href='#trlbzr'/>
            <NiceButton text="D45HBRD" href='#d45hbrd'/>
            <NiceButton text="BIOPORT" href='#bioport'/>
          </div>
        </div>
    );
  }
}

export default TopBar;
