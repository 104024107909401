const bioportmoretxt = `
Kompaktowość urządzenia pozwala przeprowadzać wiele zabiegów jednocześnie, stosując w jednym czasie i miejscu kilkanaście czynników terapeutycznych:

ultraczyste powietrze
krystaliczna woda
tlenoterapia
jonoterapia
ozonoterapia
terapia koloidami metali szlachetnych
terapia światłem
hydromasaże
balneoterapia
magnetoterapia
sonoterapia
muzykoterapia
terapia parą wodną
haloterapia
talasoterapia
aromatoterapia`
export default bioportmoretxt;
